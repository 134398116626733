import { objectForEach } from '../helpers/objectForEach'
import type { TTerminology } from './TTerminology'

export function traverseTerminologyInfo(
  symbols: TTerminology,
  cb: (messages: [string, string]) => void
): void {
  objectForEach(symbols, (symbol) => {
    cb(symbol.name)
    objectForEach(symbol.alternativeNames, cb)
  })
}
