import React from 'react'
import { Interpolator, BrowserClient } from '@workfront/localize-browser'
import { useLocalizeClient } from './useLocalizeClient'
import { useAsyncResult } from './helpers/useAsyncResult'
import type { TLocalizeContext } from './LocalizeContext'
import { getMessage, useLoadMessageKeys } from './useLocalizations'

export type TLocalizationProps<T extends string | string[]> =
  | TLocalizationMultipleProps<T>
  | TLocalizationSingleProps<T>

export type TLocalizationCommonProps<T> = {
  context?: TLocalizeContext | BrowserClient
  blocking?: boolean
  sync?: boolean
  asHtml?: boolean
}

export type TLocalizationMessageArgWithFallback = (fallback: string, ...args: unknown[]) => string
export type TLocalizationMessagesArg = Array<TLocalizationMessageArgWithFallback> & {
  _t: Record<string, TLocalizationMessageArgWithFallback>
}

export type TLocalizationMultipleProps<T> = TLocalizationCommonProps<T> & {
  messageKeys: string[]
  children?: T extends string
    ? never
    : React.ReactNode | ((messages: TLocalizationMessagesArg, isLoaded: boolean) => React.ReactNode)
}

export type TLocalizationMessageArg = (...args: unknown[]) => string
export type TLocalizationSingleProps<T> = TLocalizationCommonProps<T> & {
  messageKey: string
  fallback: string
  tag?: keyof React.ReactHTML | 'text' | 'tspan'
  args?: unknown[] | Record<string, unknown>
  children?: T extends string[]
    ? never
    : React.ReactNode | ((message: TLocalizationMessageArg, isLoaded: boolean) => React.ReactNode)
}

function isSingleKeyProps<T extends string | string[]>(
  props: TLocalizationProps<T>
): props is TLocalizationSingleProps<T> {
  return 'messageKey' in props
}

/**
 * Loads given message keys and renders children as soon as messages will be loaded
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Localization<T extends string | string[]>(props: TLocalizationProps<T>) {
  const { context, blocking = true, asHtml = false } = props

  // The 'messageKeys' conditional could make the dependencies of
  // useCallback Hook change on every render.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const messageKeys: string[] = isSingleKeyProps(props) ? [props.messageKey] : props.messageKeys

  const client = useLocalizeClient(context)
  const locale = client.getLocale()

  let { sync } = props
  if (sync === undefined) {
    sync = client.getStore().containsAll(messageKeys)
  }

  const action = useLoadMessageKeys(client, messageKeys)
  const isLoaded = useAsyncResult<void | boolean>(action, sync, sync)[1]

  if (isLoaded || !blocking) {
    const createInterpolatorFn =
      (messageKey: string) =>
      (fallback: string, ...args: unknown[]) => {
        return client
          .getInterpolator()
          .interpolateUsingChain(
            asHtml ? BrowserClient.HTML : Interpolator.DEFAULT,
            locale,
            getMessage(client, isLoaded, messageKey, fallback),
            ...args
          )
      }

    if (isSingleKeyProps(props)) {
      const { children, fallback } = props
      if (typeof children === 'function') {
        return children(createInterpolatorFn(messageKeys[0]).bind(null, fallback), isLoaded)
      } else {
        const { tag } = props
        let { args = [] } = props
        if (!Array.isArray(args)) {
          args = [args]
        }

        const content = createInterpolatorFn(messageKeys[0])(fallback, ...args)
        if (asHtml) {
          return React.createElement(tag || 'span', {
            dangerouslySetInnerHTML: {
              __html: content,
            },
          })
        } else {
          if (tag) {
            return React.createElement(tag, {}, content)
          } else {
            // According to React 18 types, 'string' is not a valid JSX element.
            // See https://github.com/DefinitelyTyped/DefinitelyTyped/discussions/53846
            // and https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20544
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return content as any
          }
        }
      }
    } else if (typeof props.children === 'function') {
      const fns: TLocalizationMessageArgWithFallback[] = []
      const fnsMessagesArg = fns as TLocalizationMessagesArg
      Object.defineProperty(fns, '_t', { value: {} })
      for (const messageKey of messageKeys) {
        const fn = createInterpolatorFn(messageKey)
        fnsMessagesArg._t[messageKey] = fn
        fns.push(fn)
      }
      return props.children(fnsMessagesArg, isLoaded)
    }
  }
  if (typeof props.children === 'function') {
    return null
  }
  return props.children || null
}
