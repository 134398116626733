import type { ITransport, TTransportResponse, TServiceRequest } from '@workfront/localize'
import { AbstractTransport } from '@workfront/localize'
import { makeTimeBoundedPromise } from './makeTimeBoundedPromise'
import type { IWindowWithVariables } from './IWindowWithVariables'

export type TFetchTransportOptions = {
  /**
   * A duration transport waits collecting requests in a buffer
   * until making actual call to transport for unbuffered transfer.
   * Defaults to 0.
   */
  flushDelayMs?: number

  /**
   * Which url to use for connecting to localizer microservice.
   * If you omit this option, window.WF_LOCALIZER_URL global variable will be used.
   * Otherwise `url` option will override window.WF_LOCALIZER_URL variable.
   * Defaults to empty string.
   */
  url?: string
}

export class FetchTransport extends AbstractTransport implements ITransport {
  protected _endpoint: string
  protected _flushDelayMs: number
  private static readonly DEFAULT_REGION = 'va7'

  constructor(options: TFetchTransportOptions) {
    super()
    if (typeof options.url === 'undefined') {
      this._endpoint = (window as IWindowWithVariables).WF_LOCALIZER_URL || '/localizer'
    } else {
      this._endpoint = options.url
    }
    this._flushDelayMs = options.flushDelayMs || 0
    this._endpoint = this._endpoint.replace(/\/+$/, '') + '/api/search/messages'
  }

  transfer(data: TServiceRequest): Promise<TTransportResponse> {
    const abortController = new AbortController()
    const headers: HeadersInit = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': 'localizer',
      // crypto.randomUUID() is supported in all browsers, but not in jsdom.
      // we use `?.` here to make sure mocks work for consumers without additional jest setup.
      'X-Request-Id': crypto.randomUUID?.()?.replaceAll('-', ''),
    }

    if (this.isRequestFromAdobeIO()) {
      headers['X-Gw-Region'] = this.getRegion() || FetchTransport.DEFAULT_REGION
    }

    return makeTimeBoundedPromise(
      fetch(this._endpoint, {
        cache: 'no-store',
        method: 'POST',
        headers,
        body: JSON.stringify(data),
        signal: abortController.signal,
      }),
      {
        timeoutRejectValue: 'timeout loading messages',
        abort: () => abortController.abort(),
      }
    ).then((response) => {
      if (response.ok) {
        return response.json()
      }
      throw new Error(`HTTP error ${response.status}: ${response.statusText}`)
    })
  }

  getFlushDelayMs(): number {
    return this._flushDelayMs
  }

  destroy(): Promise<void> {
    return Promise.resolve()
  }

  isRequestFromAdobeIO() {
    return this._endpoint.includes('adobe.io')
  }

  getRegion() {
    const wfLocalizerRegion = (window as IWindowWithVariables).WF_LOCALIZER_HEADERS_REGION
    return wfLocalizerRegion ? wfLocalizerRegion() : FetchTransport.DEFAULT_REGION
  }
}
