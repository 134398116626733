import { BrowserClient, Interpolator } from '@workfront/localize-browser'
import type { TMessagesMap, TUseLocalizationContextParam } from './useLocalizations'
import { useLocalizationsGeneric } from './useLocalizations'

export type TUseLocalizationReturnValue = [message: string, isLoaded: boolean]
export type TUseLocalizationArgs =
  | [messageKey: string, fallback: string, ...rest: unknown[]]
  | [context: TUseLocalizationContextParam, messageKey: string, ...rest: unknown[]]

/**
 * React hook, loads and returns an array of
 * localized interpolated 'message' and 'isLoading' boolean
 */
function useLocalizationGeneric(
  postProcessorChain: string,
  messageKey: string,
  fallback: string,
  ...args: unknown[]
): TUseLocalizationReturnValue
function useLocalizationGeneric(
  postProcessorChain: string,
  context: TUseLocalizationContextParam,
  messageKey: string,
  fallback: string,
  ...args: unknown[]
): TUseLocalizationReturnValue
function useLocalizationGeneric(
  postProcessorChain: string,
  ...args: TUseLocalizationArgs
): TUseLocalizationReturnValue {
  let context: TUseLocalizationContextParam
  let messagesMap: TMessagesMap

  const [firstArg, secondArg, ...restArgs] = args
  if (typeof firstArg === 'string') {
    messagesMap = {
      _: {
        messageKey: firstArg,
        fallback: secondArg,
        args: restArgs,
      },
    }
  } else {
    context = firstArg
    messagesMap = {
      _: {
        messageKey: secondArg,
        fallback: restArgs.shift() as string,
        args: restArgs,
      },
    }
  }

  const [messages, isLoaded] = useLocalizationsGeneric(postProcessorChain, context, messagesMap)
  return [messages._, isLoaded]
}

function useLocalization(
  messageKey: string,
  fallback: string,
  ...args: unknown[]
): TUseLocalizationReturnValue
function useLocalization(
  context: TUseLocalizationContextParam,
  messageKey: string,
  fallback: string,
  ...args: unknown[]
): TUseLocalizationReturnValue
function useLocalization(...args: TUseLocalizationArgs): TUseLocalizationReturnValue {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return useLocalizationGeneric(Interpolator.DEFAULT, ...args)
}

function useLocalizationHtml(
  messageKey: string,
  fallback: string,
  ...args: unknown[]
): TUseLocalizationReturnValue
function useLocalizationHtml(
  context: TUseLocalizationContextParam,
  messageKey: string,
  fallback: string,
  ...args: unknown[]
): TUseLocalizationReturnValue
function useLocalizationHtml(...args: TUseLocalizationArgs): TUseLocalizationReturnValue {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return useLocalizationGeneric(BrowserClient.HTML, ...args)
}

export { useLocalizationGeneric, useLocalization, useLocalizationHtml }
