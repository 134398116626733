import { objectForEach } from '../helpers/objectForEach'
import { DEFAULT_LOCALE, TERMINOLOGY_DEFAULTS_NS } from '../constants'
import type { MultiKeyMap } from '../helpers/MultiKeyMap'
import type { TTerminologyAlternativeNames, TTerminologyTypes, TTerminology } from './TTerminology'
import terminologyInfo from '../custom-terminology-metadata/terminology.json'
import { MessageFinder } from '../interpolation/MessageFinder'
import type { TInterpolatorOptions } from '../interpolation/Interpolator'
import { Interpolator } from '../interpolation/Interpolator'
import { arrayToObject } from '../helpers/arrayToObject'

const TERMINOLOGY_INFO = terminologyInfo.data as unknown as TTerminology

const terminologyParamPrefix = '__mlm'

export type TSymbolModifier = 'up' | 'low' | 'bold'

export type TTerminologyLabels = Partial<
  Record<
    TTerminologyTypes,
    | {
        singular: string
        plural: string
      }
    | TTerminologyAlternativeNames
    | undefined
    | null
  >
>

export type TTerminologyInterpolatorOptions = TInterpolatorOptions & {
  /**
   * @example
   * {
   *   'TASK': undefined,   // use singular/plural forms of 'TASK' label
   *   'PROJECT': 'CAMPAIGN',  // use singular/plural forms of predefined 'CAMPAIGN' label
   *   'ISSUE': { singular: 'bug', plural: 'bugs' }, // custom labels
   * }
   */
  labels: TTerminologyLabels

  /**
   * A store containing translations for default labels.
   * Use `getTerminologyMessageKeys` to get a list of message keys to load to that store.
   */
  defaultLabelsStore: MultiKeyMap<3, string, string>

  /**
   * A namespace containing translations for default labels.
   * Defaults to TERMINOLOGY_DEFAULTS_NS
   */
  defaultLabelsNamespace?: string

  /**
   * A locale to use for getting labels translations.
   * Defaults to DEFAULT_LOCALE
   */
  translationLocale?: string
}

export class TerminologyInterpolator extends Interpolator {
  protected _labels: TTerminologyLabels
  protected _symbolModifiers!: Record<TSymbolModifier, (s: string, locale: string) => string>
  protected _byPlaceholderLookupTable: [
    Record<string, TTerminologyTypes>,
    Record<string, TTerminologyTypes>,
  ]
  protected _messageFinder: MessageFinder
  protected _locale: string
  protected _terminologyParamsCache?: Record<string, string>

  constructor(options: TTerminologyInterpolatorOptions) {
    super(options)
    const { defaultLabelsStore, defaultLabelsNamespace = TERMINOLOGY_DEFAULTS_NS } = options
    this._labels = options.labels

    this._locale = options.translationLocale || DEFAULT_LOCALE
    this._messageFinder = new MessageFinder([defaultLabelsNamespace], defaultLabelsStore)

    this._byPlaceholderLookupTable = [{}, {}]
    objectForEach(TERMINOLOGY_INFO, (symbol, type) => {
      this._byPlaceholderLookupTable[0][symbol.placeholder[0]] = type
      this._byPlaceholderLookupTable[1][symbol.placeholder[1]] = type
    })

    this._symbolModifiers = {
      up: (s, locale) => {
        const firstLetter = getFirstLetter(s)
        return firstLetter.toLocaleUpperCase(locale) + s.slice(firstLetter.length)
      },
      low: (s, locale) => {
        const firstLetter = getFirstLetter(s)
        return firstLetter.toLocaleLowerCase(locale) + s.slice(firstLetter.length)
      },
      bold: (s, locale) => s.toLocaleUpperCase(locale),
    }
  }

  setTerminologyLabels(labels: TTerminologyLabels): void {
    this._labels = labels
    // reset cache, to update it during a next execution of the interpolation engine
    this._terminologyParamsCache = undefined
  }

  protected interpolationEngine(locale: string, message: string, args: unknown[]): string {
    return super.interpolationEngine(
      locale,
      message,
      message.includes(terminologyParamPrefix)
        ? [
            {
              ...arrayToObject(args),
              ...this.getTerminologyParams(),
            },
          ]
        : args
    )
  }

  protected getTerminologyParams(): Record<string, string> {
    // if we have a cached result, return it immediately to avoid expensive computations
    if (this._terminologyParamsCache) {
      return this._terminologyParamsCache
    }

    const terminologyParams: Record<string, string> = {}
    let isMessageMissing = false
    ;(Object.keys(this._symbolModifiers) as TSymbolModifier[]).forEach((modifier) => {
      objectForEach(TERMINOLOGY_INFO, (symbol) => {
        symbol.placeholder.forEach((placeholder) => {
          const terminologyKey = `${terminologyParamPrefix}_${modifier}_${placeholder}`
          const terminologyLabelInfo = this.getEffectiveTerminologyLabel(modifier, placeholder)
          if (terminologyLabelInfo.isMessageMissing) {
            isMessageMissing = true
          }
          terminologyParams[terminologyKey] = terminologyLabelInfo.label || `{${terminologyKey}}`
        })
      })
    })

    // if we're able to find all messages in store, cache the result
    if (!isMessageMissing) {
      this._terminologyParamsCache = terminologyParams
    }
    return terminologyParams
  }

  protected getEffectiveTerminologyLabel(
    modifier: string,
    symbolPlaceholder: string
  ): { label: string | undefined; isMessageMissing: boolean } {
    let symbolLabel
    let symbolLocale
    let isMessageMissing = false

    const forms = ['singular' as const, 'plural' as const]
    for (let i = 0; i < forms.length; ++i) {
      const form = forms[i]
      const lookupTable = this._byPlaceholderLookupTable[i]
      if (symbolPlaceholder in lookupTable) {
        const type = lookupTable[symbolPlaceholder]
        const symbol = TERMINOLOGY_INFO[type]
        const label = this._labels[type]
        if (label) {
          // custom label provided
          if (typeof label === 'string') {
            // use alternative label
            const alternativeName = symbol.alternativeNames[label]
            if (alternativeName) {
              const findResult = this._messageFinder.find(this._locale, alternativeName[i], '')
              symbolLabel = findResult.message
              symbolLocale = findResult.locale
              if (!symbolLocale) {
                isMessageMissing = true
              }
            }
          } else {
            // use custom text
            symbolLabel = label[form]
            symbolLocale = this._locale
          }
        } else {
          // using default naming
          const findResult = this._messageFinder.find(this._locale, symbol.name[i], '')
          symbolLabel = findResult.message
          symbolLocale = findResult.locale
          if (!symbolLocale) {
            isMessageMissing = true
          }
        }
        break
      }
    }

    if (symbolLabel) {
      return {
        label: this._symbolModifiers[modifier as TSymbolModifier](
          symbolLabel,
          symbolLocale || DEFAULT_LOCALE
        ),
        isMessageMissing,
      }
    }
    return {
      label: undefined,
      isMessageMissing,
    }
  }
}

/**
 * We need this because "JavaScript has a Unicode problem",
 * described here: https://mathiasbynens.be/notes/javascript-unicode
 *
 * Shortly speaking s.charAt() does not work correctly for characters
 * not from Basic Multilingual Plane (U+0000 → U+FFFF) range.
 *
 * For example, for '💩' (U+1F4A9 PILE OF POO):  '💩'.charAt(0) !== '💩'
 *
 * Main parts are taken from https://github.com/mathiasbynens/String.prototype.at
 */
function getFirstLetter(s: string): string {
  // Get the first code unit and code unit value
  const cuFirst = s.charCodeAt(0)
  let len = 1
  if (
    // Check if it’s the start of a surrogate pair.
    cuFirst >= 0xd800 &&
    cuFirst <= 0xdbff && // high surrogate
    s.length > 1 // there is a next code unit
  ) {
    const cuSecond = s.charCodeAt(1)
    if (cuSecond >= 0xdc00 && cuSecond <= 0xdfff) {
      // low surrogate
      len = 2
    }
  }
  return s.slice(0, len)
}
