import type { ITransport, TServiceRequest, TTransportResponse } from '@workfront/localize'
import { AbstractTransport } from '@workfront/localize'
import { makeTimeBoundedPromise } from './makeTimeBoundedPromise'
import type { IWindowWithVariables } from './IWindowWithVariables'

export type TUserDefinedL10nFetchTransportOptions = {
  flushDelayMs?: number
}

type TFetchUserDefinedL10nHeaders = {
  'Content-Type': string
  'X-Api-Key': string
  Accept: string
  'wf-customerid'?: string
  'x-gw-ims-org-id'?: string
  authorization?: string
  'X-Request-Id': string
}
type userDefinedTranslation = {
  customerId: string
  eauthTenantId: string
  imsOrgId: string
  key: string
  productId: string
  translations: Record<string, string>
}

type TCustomLocalizationsResponse = {
  results: userDefinedTranslation[]
  maxCacheAge: number
}

export class UserDefinedL10nFetchTransport extends AbstractTransport implements ITransport {
  protected _flushDelayMs: number

  protected _endpoint: string
  constructor(options: TUserDefinedL10nFetchTransportOptions) {
    super()
    this._endpoint = (window as IWindowWithVariables).WF_LOCALIZER_URL || '/localizer'

    this._flushDelayMs = options.flushDelayMs || 0
    this._endpoint = this._endpoint.endsWith('/') ? this._endpoint.slice(0, -1) : this._endpoint
    this._endpoint += '/api/messages?limit=10000&page=1'
  }

  convertResponse(response: TCustomLocalizationsResponse, keys: string[], locale: string) {
    const { maxCacheAge, results } = response

    const messages = Object.fromEntries(
      keys.map((key) => {
        const message = results.find((item) => item.key === key)
        return [key, message?.translations[locale] || '']
      })
    )

    return {
      maxCacheAge,
      results: [
        {
          locale,
          namespace: '$custom',
          messages,
        },
      ],
    }
  }

  async transfer(data: TServiceRequest): Promise<TTransportResponse> {
    const abortController = new AbortController()
    const { locale, keys = [] } = data
    const headers: TFetchUserDefinedL10nHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': 'localizer',
      // crypto.randomUUID() is supported in all browsers, but not in jsdom.
      // we use `?.` here to make sure mocks work for consumers without additional jest setup.
      'X-Request-Id': crypto.randomUUID?.()?.replaceAll('-', ''),
    }

    if (window['exc-module-runtime']) {
      const imsToken = await window['exc-module-runtime'].user().get('imsToken')
      headers['x-gw-ims-org-id'] = await window['exc-module-runtime'].user().get('imsOrg')
      headers['authorization'] = `Bearer ${imsToken}`
    } else {
      const [rxjs, auth] = await Promise.all([System.import('rxjs'), System.import('@wf-mfe/auth')])
      const currentCustomer = await rxjs.firstValueFrom(auth.getCurrentCustomer$())
      const { ID } = currentCustomer

      headers['wf-customerid'] = ID
    }

    return makeTimeBoundedPromise(
      fetch(this._endpoint, {
        cache: 'no-store',
        method: 'POST',
        headers,
        body: JSON.stringify({
          keys,
        }),
      }),
      {
        timeoutRejectValue: 'timeout loading messages',
        abort: () => abortController.abort(),
      }
    )
      .then((response) => response.json())
      .then((response) => this.convertResponse(response, keys, locale))
  }
}
