import React from 'react'
import type { BrowserClient } from '@workfront/localize-browser'
import { LoadLocalizations } from './LoadLocalizations'
import { Localization } from './Localization'
import { useLocalization, useLocalizationGeneric, useLocalizationHtml } from './useLocalization'
import { useLocalizations, useLocalizationsGeneric, useLocalizationsHtml } from './useLocalizations'
import type { Head, Tail } from './helpers/types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TWithClientFirstArgFn<F extends (...args: any[]) => any> = (
  ...args: Tail<Parameters<F>>
) => ReturnType<F>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TWithClientSecondArgFn<F extends (...args: any[]) => any> = (
  postProcessorChain: Head<Parameters<F>>,
  ...args: Tail<Tail<Parameters<F>>>
) => ReturnType<F>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function withClientFirstArg<F extends (...args: any[]) => any>(
  client: BrowserClient,
  hook: F
): TWithClientFirstArgFn<F> {
  return (...args) => hook(client, ...args)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function withClientSecondArg<F extends (arg0: any, ...args: any[]) => any>(
  client: BrowserClient,
  hook: F
): TWithClientSecondArgFn<F> {
  return (arg0, ...args) => hook(arg0, client, ...args)
}

export function createBoundLocalization(client: BrowserClient): {
  LoadLocalizations: typeof LoadLocalizations
  Localization: typeof Localization
  useLocalization: TWithClientFirstArgFn<typeof useLocalization>
  useLocalizationGeneric: TWithClientSecondArgFn<typeof useLocalizationGeneric>
  useLocalizationHtml: TWithClientFirstArgFn<typeof useLocalizationHtml>
  useLocalizations: TWithClientFirstArgFn<typeof useLocalizations>
  useLocalizationsGeneric: TWithClientSecondArgFn<typeof useLocalizationsGeneric>
  useLocalizationsHtml: TWithClientFirstArgFn<typeof useLocalizationsHtml>
} {
  return {
    LoadLocalizations: function BoundLoadLocalizations(props) {
      return <LoadLocalizations {...props} context={client} />
    },
    Localization: function BoundLocalization(props) {
      return <Localization {...props} context={client} />
    },
    useLocalization: withClientFirstArg(client, useLocalization),
    useLocalizationGeneric: withClientSecondArg(client, useLocalizationGeneric),
    useLocalizationHtml: withClientFirstArg(client, useLocalizationHtml),
    useLocalizations: withClientFirstArg(client, useLocalizations),
    useLocalizationsGeneric: withClientSecondArg(client, useLocalizationsGeneric),
    useLocalizationsHtml: withClientFirstArg(client, useLocalizationsHtml),
  }
}
