import { TERMINOLOGY_DEFAULTS_NS, TERMINOLOGY_NS_PREFIX } from '../constants'
import type { TAbstractClientOptions } from '../AbstractClient'
import { AbstractClient } from '../AbstractClient'
import type { Interpolator } from '../interpolation/Interpolator'
import { getTerminologyMessageKeys } from './getTerminologyMessageKeys'
import type { ITransportParam } from '../transport/ITransport'
import type { TTerminologyInterpolatorOptions } from './TerminologyInterpolator'
import { TerminologyInterpolator } from './TerminologyInterpolator'

export type TAbstractTerminologyEnabledClientOptions = TAbstractClientOptions & {
  /**
   * Whenever to enable terminology (MLM) feature or not.
   * Defaults to `false`.
   */
  enableTerminology?: boolean

  /**
   * Namespace with default labels for custom terminology feature.
   * Has an effect only if `enableTerminology` is set to true.
   */
  terminologyDefaultsNamespace?: string

  /**
   * Custom terminology labels queried from layout template.
   * Has an effect only if `enableTerminology` is set to true.
   */
  terminologyLabels?: TTerminologyInterpolatorOptions['labels']
}

export abstract class AbstractTerminologyEnabledClient extends AbstractClient {
  protected _enableTerminology: boolean
  protected _initialTerminologyLabels: TTerminologyInterpolatorOptions['labels'] | undefined
  protected _defaultLabelsNamespace: string
  protected _namespacesContainDefaultLabelsNamespace: boolean

  protected constructor(options: TAbstractTerminologyEnabledClientOptions) {
    super(options)
    this._defaultLabelsNamespace = options.terminologyDefaultsNamespace || TERMINOLOGY_DEFAULTS_NS
    this._enableTerminology = options.enableTerminology || false
    this._namespacesContainDefaultLabelsNamespace = false
    if (this._enableTerminology) {
      for (let i = 0; i < this._namespaces.length; i += 2) {
        this._namespaces.splice(i, 0, TERMINOLOGY_NS_PREFIX + this._namespaces[i])
      }
      this._namespacesContainDefaultLabelsNamespace = this._namespaces.includes(
        this._defaultLabelsNamespace
      )
      if (!this._namespacesContainDefaultLabelsNamespace) {
        this._namespaces.push(this._defaultLabelsNamespace)
      }
    }

    this._initialTerminologyLabels = options.terminologyLabels
  }

  getIdentifyingNamespace(): string {
    return this._enableTerminology ? this._namespaces[1] : this._namespaces[0]
  }

  isTerminologyEnabled(): boolean {
    return this._enableTerminology
  }

  getInitialTerminologyLabels(): TTerminologyInterpolatorOptions['labels'] | undefined {
    return this._initialTerminologyLabels
  }

  setTerminologyLabels(terminologyLabels: TTerminologyInterpolatorOptions['labels']): void {
    const interpolator = this.getInterpolator()
    if (interpolator instanceof TerminologyInterpolator) {
      interpolator.setTerminologyLabels(terminologyLabels)
    }
  }

  protected createInterpolator(): Interpolator {
    if (this.isTerminologyEnabled()) {
      return new TerminologyInterpolator(this.getInterpolatorOptions())
    } else {
      return super.createInterpolator()
    }
  }

  protected getInterpolatorOptions(): TTerminologyInterpolatorOptions {
    return {
      ...super.getInterpolatorOptions(),
      labels: this._initialTerminologyLabels || {},
      defaultLabelsStore: this.getStore(),
      translationLocale: this.getTranslationLocale(),
      defaultLabelsNamespace: this._defaultLabelsNamespace,
    }
  }

  protected modifyMessageKeys(namespace: string, locale: string, messageKeys: string[]): string[] {
    let keys = super.modifyMessageKeys(namespace, locale, messageKeys)
    if (this.isTerminologyEnabled() && namespace === this._defaultLabelsNamespace) {
      const terminologyMessageKeys = getTerminologyMessageKeys()
      if (this._namespacesContainDefaultLabelsNamespace) {
        keys = [...keys, ...terminologyMessageKeys]
      } else {
        keys = terminologyMessageKeys
      }
    }
    return keys
  }

  protected modifyTransportParams(transportParams: ITransportParam[]): ITransportParam[] {
    transportParams = super.modifyTransportParams(transportParams)
    if (this.isTerminologyEnabled()) {
      // request terminology-enabled version of keys only when we request their non-terminology version
      for (const terminologyTransportParam of transportParams) {
        if (terminologyTransportParam.namespace.startsWith(TERMINOLOGY_NS_PREFIX)) {
          const namespace = terminologyTransportParam.namespace.substring(
            TERMINOLOGY_NS_PREFIX.length
          )
          transportParams.some(({ namespace: ns, keys }) => {
            if (ns === namespace) {
              terminologyTransportParam.keys = terminologyTransportParam.keys.filter((key) =>
                keys.includes(key)
              )
              return true
            }
          })
        }
      }
    }
    return transportParams
  }
}
