import type { ITransport, TTransportResponse, ITransportParam } from './ITransport'
import type { TServiceRequest } from './TServiceRequest'
import type { TFlattenedTransportResponse } from './helpers'
import {
  decodeServiceRequests,
  encodeTransportArgs,
  extractRequestedTuplesFromFlattenedTransportResponse,
  flattenTransportResponses,
} from './helpers'

/**
 * A base for all transport implementations.
 *
 * Contains logic for splitting requests to chunks, based on
 * maximum number of message keys per namespace server accepts.
 *
 * Contains logic for buffering requests and flushing them after the given time.
 */
export abstract class AbstractTransport implements ITransport {
  protected _pendingRequests: Map<Promise<TTransportResponse>, Set<string>>

  private readonly _requestsBuffer: Set<string>
  private _flushPromise!: Promise<TFlattenedTransportResponse>
  private _resolve!: (response: TFlattenedTransportResponse) => void
  private _reject!: (err: unknown) => void

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _timeoutHandle: any

  load(locale: string, params: ITransportParam[]): Promise<TTransportResponse> {
    const encodedTuples = encodeTransportArgs(locale, params)
    if (this._isBuffered()) {
      for (const encodedKey of encodedTuples) {
        this._requestsBuffer.add(encodedKey)
      }
      return this._getFlushPromise().then((flushResult) => {
        return extractRequestedTuplesFromFlattenedTransportResponse(flushResult, encodedTuples)
      })
    } else {
      return this._loadEncodedTuples(encodedTuples)
    }
  }

  /**
   * Returns a duration transport waits collecting requests in a buffer
   * until making actual http call.
   * Passing -1 completely disables requests buffering.
   */
  public getFlushDelayMs(): number {
    return -1
  }

  /**
   * Returns maximum number of messages transport can request for a single namespace.
   * Transport divides payload into chunks and does a separate call for each chunk.
   * It takes this number into account during constructing the chunks.
   */
  public getMaxKeyCountPerNamespace(): number {
    return 250
  }

  /**
   * Returns maximum number of params which can be handled with a single `transfer` call.
   * Transport divides payload into chunks and does a separate call for each chunk.
   * It takes this number into account during constructing the chunks.
   */
  public getMaxParamsCountPerTransfer(): number {
    return 100
  }

  destroy(): Promise<void> {
    clearTimeout(this._timeoutHandle)
    return Promise.resolve()
  }

  protected constructor() {
    this._pendingRequests = new Map()
    this._requestsBuffer = new Set()
    this._timeoutHandle = 0
  }

  /**
   * Does a http call to localization microservice.
   * It should care only about sending request and receiving response over the network.
   * Child classes implement this method to provide platform-specific implementation.
   * @protected
   */
  protected abstract transfer(data: TServiceRequest): Promise<TTransportResponse>

  /**
   * Returns if the transport is in buffered mode
   */
  private _isBuffered(): boolean {
    return this.getFlushDelayMs() >= 0
  }

  /**
   * Schedules flush if not already scheduled and return its promise
   */
  private _getFlushPromise(): Promise<TFlattenedTransportResponse> {
    if (!this._timeoutHandle) {
      this._flushPromise = new Promise<TFlattenedTransportResponse>((resolve, reject) => {
        this._resolve = resolve
        this._reject = reject
        this._timeoutHandle = setTimeout(this._flush.bind(this), this.getFlushDelayMs())
      })
    }
    return this._flushPromise
  }

  /**
   * Flushes request buffer actually making http calls
   */
  private _flush(): void {
    const encodedKeys: string[] = []
    this._requestsBuffer.forEach((encodedKey) => encodedKeys.push(encodedKey))

    // preserve resolvers in local variables, because they'll be reset later
    const resolve = this._resolve
    const reject = this._reject

    // reset all variables to allow scheduling of new flush
    this._requestsBuffer.clear()
    this._timeoutHandle = 0

    this._loadEncodedTuples(encodedKeys).then((loadCallsResult) => {
      resolve(flattenTransportResponses([loadCallsResult]))
    }, reject)
  }

  private _loadEncodedTuples(encodedTuples: string[]): Promise<TTransportResponse> {
    const inputTupleSet = new Set<string>(encodedTuples)

    // divide params into 2 groups - tuples already included in pendingRequests and new tuples
    const newTuples: string[] = []
    const alreadyRequestedTuplePromiseSet = new Set<Promise<TTransportResponse>>()
    for (const tuple of encodedTuples) {
      let promise: Promise<TTransportResponse> | undefined
      for (const [pendingPromise, pendingTupleSet] of this._pendingRequests) {
        if (!promise) {
          if (pendingTupleSet.has(tuple)) {
            promise = pendingPromise
            alreadyRequestedTuplePromiseSet.add(promise)
          }
        }
      }
      if (!promise) {
        newTuples.push(tuple)
      }
    }

    const promises: Promise<TTransportResponse>[] = []
    alreadyRequestedTuplePromiseSet.forEach((p) => promises.push(p))

    let chunks: TServiceRequest[] = []
    if (newTuples.length > 0) {
      const keysPerNamespace = this.getMaxKeyCountPerNamespace()
      const paramsPerRequest = this.getMaxParamsCountPerTransfer()
      chunks = decodeServiceRequests(newTuples, keysPerNamespace, paramsPerRequest)
      for (const chunk of chunks) {
        promises.push(this.transfer(chunk))
      }
    }

    const requestPromise = Promise.all(promises)
      .then((responses) => {
        const flattenedResponse = flattenTransportResponses(responses)
        const combinedResponse = extractRequestedTuplesFromFlattenedTransportResponse(
          flattenedResponse,
          encodedTuples
        )
        this._pendingRequests.delete(requestPromise)
        return combinedResponse
      })
      .catch((e) => {
        this._pendingRequests.delete(requestPromise)
        throw e
      })
    this._pendingRequests.set(requestPromise, inputTupleSet)
    return requestPromise
  }
}
