import React from 'react'
import type { TBrowserClientOptions } from '@workfront/localize-browser'
import { BrowserClient } from '@workfront/localize-browser'
import type { TLocalizeContext } from './LocalizeContext'
import { LocalizeContext } from './LocalizeContext'
import { useInstance } from './helpers/useInstance'
import { useAsyncResult } from './helpers/useAsyncResult'

export type TLocalizationProviderProps<T extends BrowserClient = BrowserClient> = {
  /**
   * This function will be called to get options for the client to be created.
   * Or, you can also create a client instance yourself and return it from this function.
   */
  clientFactory: () => T | TBrowserClientOptions

  /**
   * Provide a custom context for making client available to LocalizationProvider children.
   * Useful when you need to make multiple nested LocalizationProvider components.
   */
  context?: TLocalizeContext<T>

  /**
   * This function is called when underlying client emits an error.
   */
  onError?: (error: unknown) => void
}

/**
 * Wraps all components relying on localized content.
 * Creates a BrowserClient instance under the hood and makes it available to children via React context.
 */
export const LocalizationProvider: React.FunctionComponent<
  React.PropsWithChildren<TLocalizationProviderProps>
> = (props) => {
  const [factoryResult] = useInstance(() => {
    const clientOrOptions = props.clientFactory()
    if (clientOrOptions instanceof BrowserClient) {
      return clientOrOptions
    }
    return new BrowserClient(clientOrOptions)
  })

  const initialValue =
    factoryResult.isTerminologyEnabled() && !factoryResult.getInitialTerminologyLabels()
      ? undefined
      : factoryResult
  const isLoaded = !!initialValue

  const action = React.useCallback(
    () =>
      factoryResult.loadTerminologyLabels().then((labels) => {
        factoryResult.setTerminologyLabels(labels)
        return factoryResult
      }),
    [factoryResult]
  )
  const [client] = useAsyncResult(action, initialValue, isLoaded)

  const { onError } = props
  React.useEffect(() => {
    if (onError && client) {
      const handleError = (evt: Event) => {
        onError((evt as CustomEvent).detail)
      }
      client.addEventListener('error', handleError)
      return () => client.removeEventListener('error', handleError)
    }
  }, [client, onError])

  React.useEffect(
    () => () => {
      client && client.destroy()
    },
    [client]
  )

  if (client) {
    const { Provider } = props.context || LocalizeContext
    return <Provider value={client}>{props.children}</Provider>
  }
  return null
}
