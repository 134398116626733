import React from 'react'
import { BrowserClient } from '@workfront/localize-browser'
import type { TLocalizeContext } from './LocalizeContext'
import { LocalizeContext } from './LocalizeContext'

/**
 * React hook, returns a BrowserClient instance
 */
export function useLocalizeClient(context?: TLocalizeContext | BrowserClient): BrowserClient {
  context = context || LocalizeContext

  if (!(context instanceof BrowserClient) && (!context.Provider || !context.Consumer)) {
    throw new Error(
      'Provided "context" is not a React context. Check first argument of useLocalization or useLocalizeClient hook.'
    )
  }

  // need to call useContext even if the context is a BrowserClient because of rules of hooks
  const client = React.useContext(context instanceof BrowserClient ? LocalizeContext : context)
  if (context instanceof BrowserClient) {
    return context
  }
  if (!client) {
    throw new Error('Make sure your component is a descendant of LocalizationProvider')
  }
  return client
}
