import type { IAsyncCache, ICacheQueryResult } from './caching/IAsyncCache'
import type { ITransport, ITransportParam, TTransportResponse } from './transport/ITransport'
import { objectForEach } from './helpers/objectForEach'
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from './constants'
import { MultiKeyMap } from './helpers/MultiKeyMap'
import { MessageFinder } from './interpolation/MessageFinder'
import type { TInterpolatorOptions } from './interpolation/Interpolator'
import { Interpolator } from './interpolation/Interpolator'

export type TAbstractClientOptions = {
  namespace: string | string[]
  transport: ITransport
  locale?: string
  translationLocales?: string[]
}

export type TClientStore = MultiKeyMap<3, string, string> & {
  /**
   * Lookup a value for the given message key in the underlying store,
   * using the best possible locale.
   * Returns fallback string if message key cannot be found.
   * Returns 'undefined' as a locale if message key was not found.
   */
  find: (messageKey: string, fallback: string) => { message: string; locale?: string }

  /**
   * Returns true if all the given message keys exist for the provided locale
   */
  containsAll: (messageKeys: string[]) => boolean
}

export type TAnalyzedCacheResult = {
  namespace: string
  messages: Record<string, string>
  keysToLoad: string[]
  expiredKeys: string[]
}

/**
 * A storage for client store where all messages are stored
 */
const clientStoreStorage = new MultiKeyMap(3)

/**
 * An abstract client implementation, containing platform and framework-agnostic code
 */
export abstract class AbstractClient {
  protected _namespaces: string[]
  protected _locale: string
  protected _createCachePromise?: Promise<IAsyncCache>
  protected _transport: ITransport
  protected _messageFinder?: MessageFinder
  protected _interpolator?: Interpolator
  protected _store: TClientStore
  protected _translationLocales: Set<string>

  /**
   * Call to emit custom event of the given `type` and with the `detail` data
   */
  protected abstract emitEvent(type: string, detail: unknown): void

  /**
   * Creates a new instance of cache to be used by the client
   */
  protected abstract createCache(): Promise<IAsyncCache>

  protected constructor(options: TAbstractClientOptions) {
    this._transport = options.transport
    this._namespaces = Array.isArray(options.namespace)
      ? [...options.namespace]
      : [options.namespace]
    this._locale = options.locale || this.getDefaultLocale()
    this._translationLocales = new Set(
      options.translationLocales && options.translationLocales.length > 0
        ? options.translationLocales
        : SUPPORTED_LOCALES
    )
    this._store = Object.create(clientStoreStorage)
    this._store.find = (messageKey: string, fallback: string) =>
      this.getMessageFinder().find(this.getTranslationLocale(), messageKey, fallback)
    this._store.containsAll = (messageKeys: string[]) =>
      this.getMessageFinder().containsAll(this.getTranslationLocale(), messageKeys)
  }

  /**
   * Perform cleanup of this client instance
   */
  destroy(): Promise<void> {
    return Promise.all([
      this._transport.destroy(),
      this.getCache()
        .then((cache) => cache.close())
        .then(() => (this._createCachePromise = undefined)),
    ]) as Promise<never>
  }

  /**
   * Returns first namespace used in this client instance
   */
  getIdentifyingNamespace(): string {
    return this._namespaces[0]
  }

  /**
   * Returns reference to internal store where messages are kept
   */
  getStore(): TClientStore {
    return this._store
  }

  /**
   * Returns current value of client locale.
   * That locale is used for interpolation.
   */
  getLocale(): string {
    return this._locale
  }

  /**
   * Override to provide custom logic for determining default locale which client will use
   */
  getDefaultLocale(): string {
    return DEFAULT_LOCALE
  }

  /**
   * Returns current value of client locale used for translating strings.
   * Translation locale is used when searching message in the cache or in the store or
   * during talking to localization microservice.
   * That can be different from locale returned by getLocale().
   * For example, locale can be `fr-CA`, but as we don't support Canadian French, translation
   * locale will be `fr`.
   */
  getTranslationLocale(): string {
    // use locale if it is supported
    if (this._translationLocales.has(this._locale)) {
      return this._locale
    }
    if (this._translationLocales.has('zh-TW') && this._locale.toLowerCase().startsWith('zh-hant')) {
      return 'zh-TW'
    }
    // if locale is not supported, check if its language part is supported
    const language = this._locale.split('-', 1)[0]
    if (this._translationLocales.has(language)) {
      return language
    }
    return DEFAULT_LOCALE
  }

  getCache(): Promise<IAsyncCache> {
    if (this._createCachePromise !== undefined) {
      return this._createCachePromise
    }
    this._createCachePromise = this.createCache()
    return this._createCachePromise
  }

  /**
   * Load `messageKeys` (querying cache and transport),
   * and store them in internal store
   */
  load(messageKeys: string[]): Promise<void> {
    const store = this.getStore()
    const currentLocale = this.getTranslationLocale()
    let _cache: IAsyncCache

    const cacheResults: Record<string, TAnalyzedCacheResult[]> = {
      [currentLocale]: [],
      [DEFAULT_LOCALE]: [],
    }

    // remove empty and duplicate items from messageKeys array
    const requestedMessageKeySet = new Set<string>(messageKeys.filter(Boolean))
    messageKeys = []
    requestedMessageKeySet.forEach((key) => messageKeys.push(key))

    const queryCache = (cache: IAsyncCache) => {
      _cache = cache
      const locales =
        currentLocale === DEFAULT_LOCALE ? [DEFAULT_LOCALE] : [currentLocale, DEFAULT_LOCALE]
      const promises: Promise<ICacheQueryResult>[] = []
      for (const locale of locales) {
        for (const namespace of this._namespaces) {
          promises.push(
            _cache.get(namespace, locale, this.modifyMessageKeys(namespace, locale, messageKeys))
          )
        }
      }
      return Promise.all(promises).catch((error) => {
        this.emitEvent('error', {
          op: 'readingCache',
          message: 'error reading from cache',
          error,
        })
        return [
          {
            hits: {},
            expiredKeys: [],
            missingKeys: messageKeys,
          },
        ]
      })
    }

    const parseCacheQueryResults = (results: ICacheQueryResult[]) => {
      const hitMessageKeys = new Set<string>()
      for (let i = 0; i < results.length && i < this._namespaces.length; ++i) {
        const cacheQueryResult = results[i]
        objectForEach(cacheQueryResult.hits, ({ message }, messageKey) => {
          if (message !== undefined && !cacheQueryResult.expiredKeys.includes(messageKey)) {
            hitMessageKeys.add(messageKey)
          }
        })
      }
      results.forEach((cacheQueryResult, i) => {
        const messages: Record<string, string> = {}
        objectForEach(cacheQueryResult.hits, ({ message }, messageKey) => {
          if (message !== undefined) {
            messages[messageKey] = message
          }
        })
        let locale: string
        let keysToLoad: string[]
        let expiredKeys: string[]
        if (i >= this._namespaces.length) {
          locale = DEFAULT_LOCALE
          keysToLoad = []
          expiredKeys = []
        } else {
          locale = currentLocale
          keysToLoad = cacheQueryResult.expiredKeys
            .concat(cacheQueryResult.missingKeys)
            .filter((key) => !hitMessageKeys.has(key))
          expiredKeys = cacheQueryResult.expiredKeys
        }

        const namespace = this._namespaces[i % this._namespaces.length]
        cacheResults[locale].push({
          namespace,
          messages,
          keysToLoad,
          expiredKeys,
        })
      })
      return cacheResults
    }

    const callTransport = () => {
      const transportParams: ITransportParam[] = this.modifyTransportParams(
        cacheResults[currentLocale].map(({ namespace, keysToLoad: keys }) => ({
          namespace,
          keys,
        }))
      ).filter(({ keys }) => keys.length > 0)
      if (transportParams.length > 0) {
        return this._transport.load(currentLocale, transportParams).catch((error) => {
          this.emitEvent('error', {
            op: 'loadingFromRemote',
            message: 'loading error',
            error,
          })
        })
      }
      return {
        maxCacheAge: 0,
        results: [],
      }
    }

    const updateCache = (response: TTransportResponse) => {
      const promises: Promise<void>[] = []

      const expiresAt = response.maxCacheAge > 0 ? +new Date() + response.maxCacheAge * 60 : 0
      response.results
        .filter(({ locale }) => cacheResults[locale])
        .forEach(({ namespace, locale, messages }) => {
          cacheResults[locale].some((cacheResult) => {
            if (cacheResult.namespace !== namespace) {
              return false
            }

            // filter out expired message keys already loaded by transport
            cacheResult.expiredKeys = cacheResult.expiredKeys.filter((key) => !messages[key])

            // append loaded messages to cacheResult
            cacheResult.messages = {
              ...cacheResult.messages,
              ...messages,
            }

            // remove expired keys from cache if any
            if (cacheResult.expiredKeys.length > 0) {
              for (const key of cacheResult.expiredKeys) {
                delete cacheResult.messages[key]
              }
              promises.push(_cache.remove(namespace, locale, cacheResult.expiredKeys))
            }

            // store message keys loaded by transport in cache
            promises.push(_cache.set(namespace, locale, messages, expiresAt))
            return true
          })
        })

      return Promise.all(promises).catch((error) => {
        this.emitEvent('error', {
          op: 'updatingCache',
          message: 'error updating cache entries',
          error,
        })
      })
    }

    const updateStore = () => {
      objectForEach(cacheResults, (cacheResult, locale) => {
        for (const analyzedCacheResult of cacheResult) {
          const namespace = analyzedCacheResult.namespace

          // remove expired keys from store
          for (const key of analyzedCacheResult.expiredKeys) {
            store.remove([namespace, locale, key])
          }
          this.emitEvent('storeEntriesRemoved', {
            namespace,
            locale,
            keys: analyzedCacheResult.expiredKeys,
          })

          // make loaded (from cache and transport) messages available in store
          objectForEach(analyzedCacheResult.messages, (msg, key) => {
            store.set([namespace, locale, key], msg)
            requestedMessageKeySet.delete(key)
          })
          this.emitEvent('storeEntriesUpdated', {
            namespace,
            locale,
            messages: analyzedCacheResult.messages,
          })
        }
      })
      if (!this._namespaces.includes('$custom')) {
        for (const key of requestedMessageKeySet) {
          this.emitEvent('messageKeyMissing', {
            key,
            namespaces: this._namespaces,
          })
        }
      }
    }

    return this.getCache()
      .then(queryCache)
      .then(parseCacheQueryResults)
      .then(callTransport)
      .then((response) => (response ? updateCache(response) : undefined))
      .then(updateStore)
  }

  /**
   * Load `messageKey` (querying cache and transport), store it in internal store,
   * interpolate with provided arguments and return the result
   */
  get(
    postProcessorChain: string,
    messageKey: string,
    fallback: string,
    ...args: unknown[]
  ): Promise<string> {
    const locale = this.getLocale()
    const translationLocale = this.getTranslationLocale()
    return this.load([messageKey]).then(() =>
      this.findAndInterpolate(
        postProcessorChain,
        locale,
        translationLocale,
        messageKey,
        fallback,
        ...args
      )
    )
  }

  /**
   * Locate `messageKey` in internal store, interpolate with provided arguments
   * and return the result, refreshing cache in a background
   */
  getSync(
    postProcessorChain: string,
    messageKey: string,
    fallback: string,
    ...args: unknown[]
  ): string {
    // initiate load to update expired cache entries
    this.load([messageKey])

    // and return immediately without waiting for loading to complete
    return this.findAndInterpolate(
      postProcessorChain,
      this.getLocale(),
      this.getTranslationLocale(),
      messageKey,
      fallback,
      ...args
    )
  }

  /**
   * Returns instance of Interpolator used for interpolating messages.
   * Creates an instance at the first use.
   */
  getInterpolator(): Interpolator {
    if (!this._interpolator) {
      this._interpolator = this.createInterpolator()
    }
    return this._interpolator
  }

  /**
   * Override to provide a custom Interpolator
   */
  protected createInterpolator(): Interpolator {
    return new Interpolator(this.getInterpolatorOptions())
  }

  /**
   * Override to provide custom options to default Interpolator
   */
  protected getInterpolatorOptions(): TInterpolatorOptions {
    return {
      postProcessorChains: {
        [Interpolator.DEFAULT]: [],
      },
    }
  }

  /**
   * Override to modify the set of keys to be loaded
   */
  protected modifyMessageKeys(namespace: string, locale: string, messageKeys: string[]): string[] {
    return messageKeys
  }

  /**
   * Override to modify transport params before passing them to the transport
   */
  protected modifyTransportParams(transportParams: ITransportParam[]): ITransportParam[] {
    return transportParams
  }

  protected findAndInterpolate(
    postProcessorsChainType: string,
    locale: string,
    translationLocale: string,
    messageKey: string,
    fallback: string,
    ...args: unknown[]
  ): string {
    const { message } = this.getMessageFinder().find(translationLocale, messageKey, fallback)
    return this.getInterpolator().interpolateUsingChain(
      postProcessorsChainType,
      locale,
      message,
      ...args
    )
  }

  /**
   * Returns instance of MessageFinder used for locating message in internal store.
   * Creates an instance at the first use.
   */
  private getMessageFinder(): MessageFinder {
    if (!this._messageFinder) {
      this._messageFinder = new MessageFinder(this._namespaces, this.getStore())
    }
    return this._messageFinder
  }
}
