import React from 'react'
import type { BrowserClient } from '@workfront/localize-browser'
import { useLocalizeClient } from './useLocalizeClient'
import { useAsyncResult } from './helpers/useAsyncResult'
import type { TLocalizeContext } from './LocalizeContext'

export type TLoadLocalizationsProps = {
  /**
   * Messages keys to load
   */
  messageKeys: string[]

  /**
   * Provide a custom context holding a client.
   * Useful when you have multiple nested Localization components and want to target a specific one.
   */
  context?: TLocalizeContext | BrowserClient
}

/**
 * Loads given message keys and renders children as soon as messages will be loaded
 */
export const LoadLocalizations: React.FunctionComponent<
  React.PropsWithChildren<TLoadLocalizationsProps>
> = (props) => {
  const { messageKeys } = props
  const client = useLocalizeClient(props.context)

  const messagesExistInStore = client.getStore().containsAll(messageKeys)

  const action = React.useCallback(
    () => client.load(messageKeys).then(() => true),
    // React Hook useCallback has a spread element in its dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...messageKeys]
  )
  const isLoaded = useAsyncResult(action, false, messagesExistInStore)[1]
  return isLoaded ? (props.children as never) : null
}
